<template>
  <div
    v-if="invoice"
  >
    <b-row>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Invoice ID
        </small>
        <p>
          <b-link
            :href="`/accounts/invoice/?id=${invoice.invoice_id }`"
            target="_blank"
          >
            {{ invoice.invoice_id ? invoice.invoice_id : 'N/A' }}
          </b-link>
        </p>
      </b-col>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Total Amount
        </small>
        <p>
          BDT {{ Number(invoice.total_amount ? invoice.total_amount : 0).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Status
        </small>
        <p>
          {{ invoice.status }}
        </p>
      </b-col>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Payable Amount
        </small>
        <p>
          BDT {{ invoice.payable_amount ? Number(invoice.payable_amount ? invoice.payable_amount : 0).toLocaleString() : 0 }}
        </p>
      </b-col>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Paid Amount
        </small>
        <p>
          BDT {{ Number(invoice.paid_amount ? invoice.paid_amount : 0 ).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        v-if="invoice.markup"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Markup
        </small>
        <p>
          {{ invoice.markup.markup_name }}
        </p>
      </b-col>
      <b-col
        v-if="invoice.discount"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">Discount</small>
        <p class="mb-0">
          {{ invoice.discount.discount_name }}
        </p>
        <p>{{ invoice.discount.discount_promo_code }}</p>
      </b-col>
      <b-col
        v-if="invoice.aggregated_discount"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Additional Discount
        </small>
        <p>
          {{ invoice.aggregated_discount.discount_name ? invoice.aggregated_discount.discount_name : 'N/A' }}
        </p>
        <p>
          {{ invoice.aggregated_discount.discount_promo_code }}
        </p>
      </b-col>
      <b-col
        v-if="invoice.discount || invoice.aggregated_discount || invoice.discount_amount"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Total Discount
        </small>
        <p>
          BDT {{ Number(invoice.discount_amount).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        v-if="crossSale"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">
          Cross Sale Discount
        </small>
        <p>
          {{ crossSale.discount_name }}
        </p>
        <p>{{ crossSale.discount_promo_code }}</p>
      </b-col>
      <b-col
        v-if="invoice.service_charge"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">Convenience Fee</small>
        <p>
          BDT {{ Number(invoice.service_charge).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">Commission</small>
        <p>
          BDT {{ Number(invoice.commission_amount ? invoice.commission_amount : 0).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        v-if="invoice.addon_amount"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">Addon Amount</small>
        <p>
          BDT {{ Number(invoice.addon_amount).toLocaleString() }}
        </p>
      </b-col>
      <b-col
        v-if="invoice.ancillary_amount"
        cols="4"
        md="4"
        sm="12"
      >
        <small class="text-muted">Ancillary Amount</small>
        <p>
          BDT {{ Number(invoice.ancillary_amount).toLocaleString() }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Invoice',
  props: {
    invoice: {
      type: Object,
    },
    crossSale: {
      type: Object,
    },
    invoiceType: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
