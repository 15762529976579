<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="justify-content-end">
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="end-date">To</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="departure-date">Departure Date</label>
            <b-form-datepicker
              id="departure-date"
              v-model="departureDate"
              reset-button
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              class="mb-2"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="align-self-center"
          >
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                :disabled="isBtnLoading"
                @click="getRequestedQuery()"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Set Period
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-between">
          <!-- Search -->
          <b-col
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset=0, getRequestedQuery)"
              />
            </div>
          </b-col>
          <b-col md="4">
            <v-select
              v-model="statusQuery"
              :options="status"
              :reduce="option => option.value"
              :clearable="true"
              class="status-filter-select"
              placeholder="Select Status"
              @input="getBusBookingList()"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="busList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="getBookingData(data.item)"
            />
            <b-dropdown
              v-if="['TICKETING_SUCCESS', 'BOOKING_SUCCESS', 'TICKET_ISSUED', 'BOOKING_HOLD'].includes(data.item.booking_status)"
              variant="link"
              toggle-class="p-0"
              class="action-menu"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <template v-if="data.item.booking_status === 'BOOKING_HOLD'">
                <b-dropdown-item
                  @click="getRequestedAction({ product_reference: data.item.booking_id }, 'Send Payment Link', 'send payment link',
                                             'user_journey/offline_invoice/assisted_payment/',
                                             'post', false)"
                >
                  <feather-icon icon="SendIcon" />
                  <span class="align-middle ml-50">Send Payment Link</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                v-if="data.item.booking_status === 'TICKETING_SUCCESS' &&
                  allowCancellationBtn(data.item.booking_status.slice(data.item.booking_status.lastIndexOf('_') + 1),
                                       data.item.rate_class)"
                @click="checkForCancellability(data.item)"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel Booking</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.booking_status === 'BOOKING_SUCCESS'"
                @click="getRequestedAction({ invoice_id: data.item.invoice.invoice_id}, 'Confirm Ticket', 'confirm ticket',
                                           '/user_journey/booking_confirmation/',
                                           'post', false)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Confirm Ticket</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.booking_status === 'TICKETING_SUCCESS'"
                @click="getRequestedAction({}, 'Download Voucher', 'download voucher for',
                                           `/user_journey/invoice/${data.item.invoice.invoice_id}/voucher_download/`,'get', true)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download Voucher</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(booking_status)="data">
          <b-badge
            pill
            class="cursor-pointer"
            :variant="formatClassName(data.item.booking_status)"
          >
            {{ formatStatus(data.item.booking_status) }}
          </b-badge>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="busList"
        @paginate="paginate"
      />
      <BusBookingDetails :bus-booking-data="bookingDetail" />
      <BusActions
        :modal-data="busActionsData"
        @getBusList="getBusBookingList()"
      />
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TablePagination from '@/components/common/TablePagination.vue'
import BusBookingDetails from '@/views/bookings/bus/BusBookingDetails.vue'
import BusActions from '@/views/bookings/bus/BusActions.vue'

export default {
  name: 'BusBookingList',
  components: {
    TablePagination,
    BusBookingDetails,
    BusActions,
  },
  mixins: [helper, calendar],
  data() {
    return {
      isTableLoading: false,
      isBtnLoading: false,
      busList: [],
      bookingDetail: {},
      busActionsData: {},
      searchQuery: '',
      statusQuery: '',
      startDate: '',
      endDate: '',
      departureDate: '',
      status: [
        {
          label: 'On Hold',
          value: 'BOOKING_HOLD',
        },
        {
          label: 'Pending Bookings',
          value: 'BOOKING_SUCCESS',
        },
        {
          label: 'Cancelled Bookings',
          value: 'BOOKING_CANCELLED',
        },
        {
          label: 'Confirmed Tickets',
          value: 'TICKETING_SUCCESS,TICKET_ISSUED',
        },
        {
          label: 'Cancelled Tickets',
          value: 'TICKET_CANCELLED',
        },
        {
          label: 'Failed Tickets',
          value: 'TICKETING_FAILED',
        },
      ],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        { key: 'booking_id', label: 'Booking Id' },
        {
          key: 'created_at', label: 'Booking Time', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM YYYY, hh:mm A'),
        },
        {
          key: 'booking_status', label: 'Status', thClass: 'md',
        },
        {
          // eslint-disable-next-line no-nested-ternary
          key: 'user', label: 'Booked By', thClass: 'md', formatter: (value, _, item) => (item.agent ? item.agent : value ? value.email : 'N/A'),
        },
        {
          key: 'cart.bus_operator.name', label: 'Provider Name', thClass: 'lg',
        },
        {
          key: 'cart.journey_date', label: 'Journey Date', thClass: 'md', formatter: value => this.formatDate(value, 'DD MMM YYYY'),
        },
        {
          key: 'cart.boarding_point', label: 'Boarding', thClass: 'lg', formatter: value => this.formatPoints(value),
        },
        {
          key: 'cart.dropping_point', label: 'Drop off', thClass: 'lg', formatter: value => this.formatPoints(value),
        },
        {
          key: 'cancellation_date', label: 'Cancellation Date', thClass: 'lg', formatter: value => (value ? this.formatDate(value, 'DD MMM YYYY, hh:mm A') : 'N/A'),
        },
        {
          key: 'invoice.payable_amount', label: 'Payable Amount', thClass: 'md', formatter: value => `BDT ${Number(value).toLocaleString()}`,
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&date_from=${this.startDate}` : ''
      const endDate = this.endDate ? `&date_to=${this.endDate}` : ''
      const departure = this.departureDate ? `&journey_date=${this.departureDate}` : ''
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const status = this.statusQuery ? `&status=${this.statusQuery}` : ''
      return `${startDate}${endDate}${departure}${search}${status}`
    },
  },
  mounted() {
    this.getBusBookingList()
  },
  methods: {
    formatPoints(value) {
      return value ? `${value.reportingTime} - ${value.counterName}` : 'N/A'
    },
    getBusBookingList() {
      this.isTableLoading = true
      api.getData(`/bus_services/go_biz/booking_list/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.busList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
        this.isBtnLoading = false
      })
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getBusBookingList()
    },
    getRequestedQuery() {
      this.isBtnLoading = true
      this.meta.offset = 0
      this.getBusBookingList()
    },
    getBookingData(bookingData) {
      this.bookingDetail = bookingData
      this.$root.$emit('bv::toggle::collapse', 'bus-details-sidebar')
    },
    allowCancellationBtn(state, rateClass) {
      return !(state === 'FAILED' || state === 'CANCELLED')
          && (rateClass === '' || rateClass === 'NOR')
    },
    checkForCancellability(data) {
      this.isLoading = true
      api.getData(`bus_services/cancelable_ticket_checker/${data.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.getRequestedAction({ booking_id: data.id }, 'Cancel Booking', 'cancel ticket',
              'bus_services/cancel_ticket/',
              'delete', false)
          } else {
            this.notificationMessage('danger', 'fas fa-times', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'fas fa-times', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          this.isLoading = false
        })
    },
    getRequestedAction(data, title, text, apiEndpoint, apiMethod, isDownload) {
      this.$root.$emit('bv::show::modal', 'bus-modal')
      this.busActionsData = {
        data,
        title,
        text,
        apiEndpoint,
        apiMethod,
        isDownload,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
