<template>
  <b-modal
    id="bus-modal"
    ref="bus-modal"
    ok-only
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    :title="modalData.title"
  >
    <b-card-text>
      Are you sure you want to {{ modalData.text }} for this booking?
    </b-card-text>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disabled="isActionLoading"
        @click="submitData()"
      >
        <b-spinner
          v-if="isActionLoading"
          small
        />
        Confirm
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'BusActions',
  mixins: [helper],
  props: {
    modalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActionLoading: false,
    }
  },
  methods: {
    submitData() {
      let apiRequest = ''
      const formData = JSON.parse(JSON.stringify(this.modalData.data))
      if (this.modalData.isDownload) {
        formData.download = true
      }
      if (this.modalData.apiMethod === 'post') {
        apiRequest = api.postData(this.modalData.apiEndpoint, true, formData)
      } else if (this.modalData.apiMethod === 'get') {
        apiRequest = api.getData(this.modalData.apiEndpoint, true)
      } else if (this.modalData.apiMethod === 'delete') {
        apiRequest = api.deleteData(this.modalData.apiEndpoint, true, formData)
      } else {
        apiRequest = api.updateData(this.modalData.apiEndpoint, true, formData)
      }
      this.getResponse(apiRequest)
    },
    getResponse(apiRequest) {
      this.isActionLoading = true
      apiRequest.then(response => {
        if (response.data.status) {
          if (this.modalData.isDownload) {
            window.open(response.data.result.url)
          }
          this.$emit('getBusList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', `${JSON.stringify(response.data.error.message)}`)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isActionLoading = false
        this.$refs['bus-modal'].hide()
      })
    },
  },
}
</script>

<style scoped>

</style>
