import moment from 'moment'

export default {
  methods: {
    getDiff(startDate, endDate) {
      return moment(endDate).diff(moment(startDate), 'days')
    },
    setStartDate(startDate, endDate) {
      if (this.getDiff(startDate, endDate) < 0) {
        return moment(endDate).subtract(6, 'days').format('YYYY-MM-DD')
      }
      return startDate
    },
    setEndDate(startDate, endDate) {
      if (this.getDiff(startDate, endDate) < 0) {
        return moment(startDate).add(6, 'days').format('YYYY-MM-DD')
      }
      return endDate
    },
  },
}
