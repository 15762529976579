<template>
  <div class="px-2">
    <b-row class="mb-1">
      <b-col cols="4">
        <small class="text-muted">Full Name</small>
      </b-col>
      <b-col cols="5">
        <small class="text-muted">Email</small>
      </b-col>
      <b-col cols="3">
        <small class="text-muted">Phone</small>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col cols="4">
        <p>{{ contactInfo.name }}</p>
      </b-col>
      <b-col cols="5">
        <p>{{ contactInfo.email }}</p>
      </b-col>
      <b-col cols="3">
        <p>{{ contactInfo.phone }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PrimaryContact',
  props: {
    contactInfo: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
